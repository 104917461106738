<template>
  <div>
    <header-slot />

    <!-- Tab -->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.inProcessTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[1].name == $route.meta.inProcessTab"
      >
        In process

        <span class="ml-2" v-if="counter.inProcess">
          <feather-icon
            icon
            :badge="counter.inProcess <= 99 ? counter.inProcess : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.pendingTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[1].name == $route.meta.pendingTab"
      >
        Pending
        <span class="ml-2" v-if="counter.pending">
          <feather-icon
            icon
            :badge="counter.pending <= 99 ? counter.pending : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.returnedTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[1].name == $route.meta.returnedTab"
      >
        Returned
        <span class="ml-2" v-if="counter.returned">
          <feather-icon
            icon
            :badge="counter.returned <= 99 ? counter.returned : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.completedTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[1].name == $route.meta.completedTab"
      >
        Completed
        <span class="ml-2" v-if="counter.completed">
          <feather-icon
            icon
            :badge="counter.completed <= 99 ? counter.completed : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LettersService from "@/views/administration/views/letters/services/letters.service.js";
export default {
  data() {
    return {
      counter: {
        inProcess: 0,
        pending: 0,
        returned: 0,
        completed: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    routerName() {
      return this.$route.matched[2].name;
    },
  },
  async created() {
    await this.getCounterLettes();
  },
  methods: {
    async getCounterLettes() {
      const { data, status } = await LettersService.getCounterTabLetter({
        id: this.currentUser.user_id,
        role: this.currentUser.role_id,
        modul: this.$route.matched[0].meta.module,
      });
      if (status == 200) {
        this.counter.inProcess = data[0].cont1;
        this.counter.pending = data[0].cont2;
        this.counter.returned = data[0].cont3;
        this.counter.completed = data[0].cont4;
      }
    },
  },
};
</script>

<style></style>
